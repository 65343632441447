.QuestionListPanel {
  width: 100%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 20px;
}

.QuestionListPanel button {
  width: 100%;
  margin: 15px auto;
  padding: 12px;
  -moz-box-sizing: border-box;
  font-size: 15px;
  box-sizing: border-box;
  border: none;
  background-color: #f7f7f7;
  color: #333333;
  display: flex;
  border: 1px solid #e0e0e0;
}

.QuestionListPanel button:hover {
  background-color: #e7f7fd;
  border: 1px dashed #34baf2;
}

.Choose {
  font-size: 15px;
  margin-top: 20px;
}

.Icon {
  margin-right: 10px;
}
/* .AddQuestionBtn {
  background-color: #34baf2;
}
.AddQuestionBtn:hover {
  background-color: #0069d9;
} */
