.currentCompany {
  display: flex;
  padding: 15px;
  background-color: white;
}

.currentlogo {
  border-radius: 10px;
  width: 75px;
  height: 75px;
  margin-left: 15px;
}

.compname {
  margin-left: 15px;
}

.vertical {
  border-left: 1px solid #d1d6e0;
  height: inherit;
  margin-left: 20px;
}

.HomePage {
  padding: 15px 15px;
  margin: 10px auto;
  height: 100%;
  width: 95%;
  box-sizing: border-box;
  min-height: calc(100vh - 171px) !important;
}

@media (max-width: 1024px) {
  .HomePage {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .BtnGroup {
    display: none !important;
  }
  .dropdown {
    display: block !important;
  }
}

.dropdown {
  display: none;
}

.compMemb {
  display: block;
}

.BtnGroup {
  display: flex;
  margin-left: 20px;
  margin-top: 15px;
}

.nav {
  height: fit-content;
  color: white;
  border: 2px solid #34baf2;
  padding: 10px;
  background-color: #34baf2;
  border-radius: 5px;
  margin-left: 15px;
  font-weight: bold;
}

.HelpLink {
  color: inherit;
  text-decoration: inherit;
}

.HelpLink:hover {
  color: inherit;
  text-decoration: inherit;
}

.main {
  background-color: #f6f6f6;
  height: max-content;
}

.renewbtn {
  color: white;
  background-color: #34baf2;
  padding: 7px;
  border: none;
  margin-left: 15px;
}
