.Header {
    background-color: #F6F7F9;
    padding: 25px;
    display: flex;
}

.HeaderText {
    margin-top: 5px;
}

.RoleText {
    margin-top: 5px;
    margin-left: 10px;
}

.spanrole {
    padding: 3px;
    background-color: #E7F7FD;
    border: 2px solid #34baf2;
    border-radius: 5px;
    margin-left: 7px;
}

.Back {
    background-color: #34baf2;
    padding: 6px;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 15px;
}

.HomePage {
    padding: 15px 15px;
    margin: 10px auto;
    width: 95%;
    box-sizing: border-box;
  }

  .vertical {
    border-left: 1px solid #d1d6e0;
    margin-left: 20px;
    height: inherit;
}

.UserHeader {
    display: flex;
    background-color: #34baf2;
    margin-top: 45px;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.text {
    font-size: 20px;
    color: white;
}

.HeaderTable th {
    border: none;
    color: white;
}