.ComparativeTableContainer {
  width: 60%;
}
.InputField {
  width: 95%;
  margin-bottom: 2.5px;
  border-color: green;
  border-radius: 6px;
  border-style: solid;
}
.InputFieldMandatory {
  border-color: red;
}
.ColumnHeader {
  display: flex;
  align-items: center;
  flex-direction: column;
}
