.Flex {
  width: 95%;
  margin: 0 3px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}

.Label {
  text-align: center;
  background-color: #a6a6a6;
}

.optionValue {
  font-weight: bold;
  margin-left: 12px;
}
