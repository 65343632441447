.Question {
  width: 90%;
  flex: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 0px;
  box-sizing: border-box;
}

.Label {
  font-size: 20px;
  color: #333333;
  /* display: block; */
  margin-bottom: 15px;
  font-family: Arial, sans-serif;
  margin-top: 5px;
  text-align: left;

  min-height: 24px;
}
/*---------------------------------------------------------------------------------------------------------*/
.Input {
  outline: none;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 1px;
  font: inherit;
  padding: 8.5px 14px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  transition: 300ms;
}

.Input:hover {
  outline: none;
  border: 2px solid rgba(22, 22, 22, 0.8);
}

.Input:focus {
  outline: none;
  border: 2px solid #34baf2;
  background-color: rgb(232, 240, 254);
}
/*-------------------------------------------------------------------------------------------------------*/
.Textarea {
  outline: none;
  border: 2px solid #ccc;
  background-color: white;
  border-radius: 5px;
  font: inherit;
  height: 5em;
  padding: 8.5px 14px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  transition: 300ms;
}

.Textarea:hover {
  outline: none;
  border: 2px solid rgba(22, 22, 22, 0.8);
}

.Textarea:focus {
  outline: none;
  border: 2px solid #34baf2;
  background-color: rgb(232, 240, 254);
}
/*----------------------------------------------------------------------------------------------------------*/
.GroupChoices {
  text-align: left;
}
/*----------------------------------------------------------------------------------------------------------*/
.Container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.Container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.Checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1em;
  width: 1em;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 9px;
  background-color: #fff;
  transition: 300ms;
}

/* On mouse-over, add a grey background color */
.Container:hover input ~ .Checkmark {
  border: 2px solid rgba(22, 22, 22, 0.8);
}

/* When the checkbox is checked, add a blue background */
.Container input:checked ~ .Checkmark {
  background-color: #43c6c3;
  border: 2px solid #43c6c3;
}

/* Create the Checkmark/indicator (hidden when not checked) */
.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the Checkmark when checked */
.Container input:checked ~ .Checkmark:after {
  display: block;
}

/* Style the Checkmark/indicator */
.Container .Checkmark:after {
  left: 0.28em;
  top: 0.08em;
  width: 8px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*-------------------------------------------------------------------------------------------*/

.RadioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.RadioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.RadioCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5em;
  width: 1.5em;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 50%;
  transition: 300ms;
  justify-content: center;
}

/* On mouse-over, add a grey background color */
.RadioContainer:hover input ~ .RadioCheckmark {
  border: 2px solid rgba(22, 22, 22, 0.8);
}

/* When the radio button is checked, add a blue background */
.RadioContainer input:checked ~ .RadioCheckmark {
  transition: 400ms;
  background-color: #43c6c3;
  border: 2px solid #43c6c3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.RadioCheckmark:after {
  content: "";
  position: absolute;
  justify-content: center;
  justify-items: center;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.RadioContainer input:checked ~ .RadioCheckmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.RadioContainer .RadioCheckmark:after {
  left: 0.35em;
  top: 0.3em;
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  background: white;
}
/*---------------------------------------------------------------------------------------------------------*/

.Rating,
.resetIconField {
  display: none;
}

.resetIcon {
  display: flex;
  justify-content: flex-end;
  font-size: 32px;

  svg {
    cursor: pointer;
  }
}

/*---------------------------------------------------------------------------------------------------------*/
@media (max-width: 900px) {
  .Question {
    width: 95%;
    padding: 8px;
  }
}

@media (max-width: 600px) {
  .Question {
    width: 95%;
    padding: 5px;
  }
}
