.SurveyListContainer .MuiAccordion-root .MuiAccordionDetails-root {
  flex-direction: column;
}
.CheckAllText {
  cursor: pointer;
  color: #333333;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.CheckAllText h4 {
  margin-bottom: 0;
  color: #333333;
  font-weight: 400;
}
