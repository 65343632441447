.NewSurvey {
  background-color: #34baf2;
  color: white;
  border: 2px solid #34baf2;
  font-weight: bold;
  padding: 10px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DialogTitle {
  color: #34baf2;
  font-size: 1.3em;
}

.NewSurvey:hover {
  background-color: white;
  color: #34baf2;
  border: 2px solid #34baf2;
}

.Icon {
  margin-right: 10px;
}

.Info {
  margin: 20px;
}

.CancelButton {
  background-color: #ee3a2c;
  color: white;
  font-size: 15px;
  border: none;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.CreateButton {
  background-color: #218838;
  color: white;
  font-size: 15px;
  border: none;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.name {
  padding: 12px;
  border: 2px solid #34baf2;
  border-radius: 5px;
  width: 37vw;
  background-color: #f6f7f9;
  margin-bottom: 20px;
}

.email {
  padding: 12px;
  border: 2px solid #34baf2;
  border-radius: 5px;
  width: 37vw;
  background-color: #f6f7f9;
  margin-bottom: 20px;
}

.role {
  padding: 12px;
  border: 2px solid #34baf2;
  border-radius: 5px;
  background-color: #f6f7f9;
  width: 37vw;
  background-color: #34baf2;
  color: white;
  margin-bottom: 20px;
}

.crbtns {
  display: flex;
}

.RoleBreak {
  margin-left: 20px;
  background-color: #34baf2;
  color: white;
  border: none;
  padding: 8px;
}

.thead {
  background-color: #34baf2;
  color: white;
}
