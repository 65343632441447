.User{
    display: flex;
}

.text {
    font-size: 20px;
    margin-right: 70px;
}




.Edit {
    border: none;
    background-color: #34baf2;
    color: white;
    border-radius: 5px;
    padding-top : 9px;
    padding-bottom: 9px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 10px;
}

.Delete {
    border: none;
    background-color:red;
    color: white;
    border-radius: 5px;
    padding : 9px;
    margin-right: 10px;
}



  .UserItem {
      margin-top: 40px;
  }

  .Name {
    border-right: 1px solid #34baf2;
  }

  .Email {
    border-right: 1px solid #34baf2;
  }

  .Role {
    border-right: 1px solid #34baf2;
  }

  .Btns {
    border-left: 1px solid #34baf2;
  }

  .table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  padding: "7px";
}

.row:nth-child(even){background-color: #f5f5f5;}

.row:hover {background-color: #ddd; cursor: pointer;}

.head {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #34baf2;
    color: white;
    border-top-left-radius: 20px;
  }

  .td {
    border: 1px solid #ddd;
  }

  .th {
      color: white;
  }