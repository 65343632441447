.Survey {
  width: 80%;
  margin: 20px auto;
  text-align: center;
  border-radius: 3px;
  padding: 10px;
  box-sizing: border-box;
  padding-bottom: 16px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 25px -13px rgba(189, 189, 189, 1);
  -moz-box-shadow: 0px 0px 25px -13px rgba(189, 189, 189, 1);
  box-shadow: 0px 0px 25px -13px rgba(189, 189, 189, 1);
  border-top: 50px solid #34baf2;
}
.SurveyForm {
  .rt-td {
    overflow-x: unset;
  }
}
.CompSurvey {
  width: unset;
  margin: 20px 20px;
}
.draftbtn {
  padding: 15px;
  color: white;
  background-color: #ee3a2b;
  border-radius: 7px;
  border: none;
  margin-left: 20px;
}

.Survey ul {
  list-style: none;
  padding: 0 10px;
}

.Title {
  color: #34baf2;
  font-size: 2.2em;
  margin: 0.6em auto;
}

.Rules {
  margin: 0;
  text-align: right;
  font-size: 0.9em;
  font-weight: bold;
  color: #e91e63;
}
.Subtitle {
  white-space: pre-wrap;
  text-align: center;
  margin: 0;
}
.Block {
  margin: 20px;
  padding: 10px;
  box-sizing: border-box;
}

.Success {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5em;
  letter-spacing: 0.1em;
  color: #34baf2;
}

.Error h3 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5em;
  letter-spacing: 0.1em;
  color: #e91e63;
}

.Error button {
  font-size: 20px;
  padding: 10px;
  background: #34baf2;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

@media (max-width: 900px) {
  .Survey {
    width: 90%;
    padding: 8px;
    padding-bottom: 16px;
  }
}

@media (max-width: 600px) {
  .Survey {
    width: 95%;
    padding: 5px;
    padding-bottom: 16px;
  }
}

.SubmitBtn {
  background-color: #34baf2;
  color: white;
  padding: 10px;
  border: none;
  font-size: 18px;
}

.LoginToContinue {
  border: none;
}

.errorun {
  width: 100%;
  width: 50%;
  max-width: 800px;
  min-width: 350px;
  height: auto;
  height: 50%;
  user-select: none;
}

.line {
  margin-top: 20px;
  border-top: 2px #cccccc;
}
.QuestionList {
  width: 100%;
  list-style: none;
  padding: 0 10px;
  margin: 5px auto;
  box-sizing: border-box;
}

.QuestionList li {
  display: block;
  margin: 25px auto;
  border: 0.5px solid #cccccc;
  border-radius: 1px;
  background-color: #fdfdfd;
  box-sizing: border-box;
  transition: 300ms;
}
.ComparativeTableContainer {
  display: flex;
}
/* .QuestionListContainer {
  max-width: 40%;
  flex: 1;
  padding-top: 65px;
} */

.CompQuestionListContainer {
  padding-top: 70px;
  width: 40%;
}
.QuestionListContainer {
  padding-top: 65px;
  flex: 1;
  max-width: 100%;
}

.QuestionList .CompLI {
  margin-top: 0px;
  background-color: #e7f7fd;
  border: 1px solid #34baf2;
}
.QuestionList .GroupLastQuestion {
  margin-bottom: 0px;
}
.ComparativeTableContainer .QuestionListContainer .GroupedQuestionList {
  margin: 0px auto 0px auto;
}
.ComparativeTableContainer .CompQuestionListContainer .GroupedQuestionList {
  margin: 0px auto 0px auto;
}
.SurveyPageUngroupedQuestion {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 300ms;
}
.ComparativeTableContainer
  .QuestionListContainer
  .SurveyPageUngroupedQuestionList {
  margin-top: 25px;
}
.ComparativeTableContainer
  .CompQuestionListContainer
  .SurveyPageUngroupedQuestionList {
  margin-top: 25px;
}
