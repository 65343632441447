.Report {
  width: 100vw;
  margin: 20px auto;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.Counter {
  margin: 1.5em 30px;
  color: #34baf2;
  letter-spacing: 0.1em;
}

@media print {
  .Counter {
    display: none;
  }
}

.Report button {
  margin: 0 30px;
  color: white;
  background: red;
  font-size: 20px;
  padding: 15px;
}
