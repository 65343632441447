.ReportItemWrapper {
  width: 90vw;
  padding: 8px;
  margin: 30px auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgba(204, 204, 204, 0.3);
  box-sizing: border-box;
}

@media print {
  .ReportItemWrapper {
    margin-top: 220px;
    page-break-after: always;
    background-color: white;
    padding: 0;
    border: none;
  }
  .table {
    margin-top: 60px;
  }

  .pie {
    margin-top: 60px;
  }
}

.ReportItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Title {
  margin-left: 20px;
  margin-top: 0.75em;
  /* margin-bottom: 0.75em; */

  min-height: 34px;
}

.Graph {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 0.25em;
  font-size: 0.8em;
}

.IndExport {
  color: black;
}
