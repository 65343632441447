.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}

@media (max-width: 800px) {
  .NavigationItems {
    font-size: 11px;
  }
  .email {
    font-size: 9px;
  }
}

@media (min-width: 800px) {
  .NavigationItems {
    flex-flow: row;
  }
  .email {
    flex-flow: row;
    vertical-align: auto;
  }
}

.usermail {
  display: flex;
  vertical-align: baseline;
}

.email {
  font-size: 15px;
  color: white;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
}

.LoginBt {
  border: 2px solid #EE382B;
  background-color: #EE382B;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: 500;
  padding-left: 15px;
  text-decoration: none;
  padding-right: 15px;
  border-radius: 4px;
}


.dropdown {
  font-size: 20px;  
  border: none;
  outline: none;
  color: white;
  font-weight: bold;
  background-color: inherit;
  font-family: inherit;
}

.dropbtn {
  font-size: 5px;  
  border: none;
  outline: none;
  color: white;
  font-weight: bold;
  margin-right: 90px;
  margin-bottom: 8px;
  background-color: inherit;
  font-family: inherit;
}

.dropdowncontent {
  display: none;
  position: relative;
  background-color: #f9f9f9;
  min-width: 160px;
  margin-top: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdowncontent h5 {
  float: none;
  color: #495058;
  padding: 15px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 20px;
}

.dropdowncontent a:hover {
  background-color: red;
}

.dropdown:hover .dropdowncontent {
  display: block;
}

.menu {
  display: flex;
  vertical-align: middle;
}

.iconn {
  color: #495058;
  margin-top: 17px;
  margin-right: 8px;
  margin-left: 15px;
}

.cairrlog {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}

.navbar {
  margin-left: 15px;
}

.adminbtn {
  border: none;
  color: white;
  font-weight: 500;
  padding: 8px;
  margin-right: 10px;
  background-color: #EE382B;
}

.navmain {
  display : flex;
  align-items : center;
  justify-content: center;
}