.MuiDialogTitle-root {
  background-color: #34baf2;
}
.MuiDialogTitle-root .MuiTypography-h6 {
  color: white;
}
.MuiDialogTitle-root button {
  color: white;
}
.MuiDialogActions-root {
  background-color: #34baf2;
}
.MuiDialogActions-root .MuiButton-root {
  color: white;
}
.MuiDialogActions-root .MuiButton-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.MuiGrid-root .MuiGrid-item .MuiButton-root:hover {
  color: #34baf2;
}
.MuiDialog-paper {
  width: 80%;
}
.MuiGrid-root .MuiGrid-item:first-child {
  flex: 1;
  max-width: 45%;
}
.MuiGrid-root .MuiGrid-item:first-child .MuiPaper-rounded {
  width: 100%;
}
.MuiGrid-root .MuiGrid-item:last-child {
  flex: 1;
  max-width: 45%;
}
.MuiGrid-root .MuiGrid-item:last-child .MuiPaper-rounded {
  width: 100%;
}
.MuiGrid-root .MuiPaper-root .Mui-checked {
  color: #34baf2;
}
.MuiDialog-paper .MuiDialogContent-root:nth-child(2) div:nth-child(2) {
  width: unset;
  word-wrap: break-word;
}
