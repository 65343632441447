.companyHeader {
    display: flex;
    padding: 22px;
    width: 100%;
    justify-content: space-between;
    background-color: white;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    margin: auto;
}

.SurveyItem {
    margin: 30px auto;
    background-color: white;
    padding: 25px;
    border: 2px solid #e8e8e8;
    border-radius: 9px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    box-sizing: border-box;
    transition: 300ms;
    -webkit-box-shadow: 0px 0px 25px -13px rgba(189, 189, 189, 1);
    -moz-box-shadow: 0px 0px 25px -13px rgba(189, 189, 189, 1);
    box-shadow: 0px 0px 25px -13px rgba(189, 189, 189, 1);
    align-items: center;
  }

  .DeleteButton {
    padding: 15px;
    color: white;
    background-color: #ee3a2c;
    border: none;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
  }

  .empty {
      display: flex;
      align-items: center;
      color: white;
      background-color: #34baf2;
      margin: 100px;
      padding: 20px;
      border-radius: 9px;
      justify-content: center;
  }

  .SurveyItem h3 {
    color: #333333;
    font-weight: 400;
  }

.compinfo {
    display: flex;
    align-items: center;
}

.logo {
    border-radius: 10px;
  width: auto;
  height: 100px;
  margin-left: 15px;
}

.Name {
    font-size: 30px;
    margin-left: 15px;
    margin-top: 15px;
}

.vertical {
    border-left: 1px solid #d1d6e0;
    height: inherit;
    margin-left: 20px;
}

.cin {
    display: flex;
    margin-top: 10px;
    margin-left: 15px;
    background-color: #d3e7f8;
    padding: 10px;
    height: fit-content;
    border-radius: 3px;

}

.cinnum {
    color: #2288dc;
    font-size: 15px;
    margin-top: 5px;
}

@media (max-width: 1000px) {
    .logo {
        width: auto;
        height: 100px;
    }
    .Name {
        font-size: 20px;
        margin-left: 10px;
        margin-top: 12px;
        display: none;
    }
    .cin {
        display: none;
    
    }
    .vertical {
        display: none;
    }
    .companyHeader {
        align-items: center;
        text-align: center;
        justify-content: center;
    }
  }

  .errormain {
        width: 100%;
        margin: 20px auto;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 10px;
        box-sizing: border-box;
        padding-bottom: 16px;
        background-color: rgba(204, 204, 204, 0.15);
  }

  .Error h3 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.5em;
    letter-spacing: 0.1em;
    color: #e91e63;
  }
  
  .Error button {
    font-size: 20px;
    padding: 10px;
    background: #34baf2;
    color: white;
    font-weight: bold;
    border-radius: 5px;
  }

  .LoginToContinue {
      background-color: #34baf2;
      font-size: 18px;
      color: white;
      padding: 10px;
  }