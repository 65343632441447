

.Logo {
    padding: 40px;
    margin-right: 100px;
    margin-left: 50px;
    margin-top: 30px;
}

.CompanyDetails {
    padding: 25px;
    display: block;
}

.InnerWrapper {
    display: flex;
}

.SaveBtn {
    padding: 15px 25px;
    color: white;
    font-size: 15px;
    background-color: #34baf2;
    border: none;
    margin-bottom: 50px;
}

.cancelbtn {
    padding: 15px 25px;
    color: white;
    font-size: 15px;
    background-color: #EE3A2C;
    border: none;
    margin-right: 15px;
    margin-bottom: 50px;
}

.Input {
    padding: 15px;
    border: 2px solid #34baf2;
    width: 50vw;
    border-radius: 5px;
    margin-bottom: 15px;
}

.EditWrapper {
    margin-top: 40px;
}

.Header {
    background-color: #F6F7F9;
    padding: 25px;
    display: flex;
}

.Clogo {
    width: 225px;
    height: 225px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.uploadLogo {
    margin-top: 15px;
}

.Back {
    background-color: #34baf2;
    padding: 6px;
    color: white;
    border: none;
    margin-left: 15px;
}

.vertical {
    border-left: 1px solid #d1d6e0;
    margin-left: 20px;
    height: inherit;
}

.HeaderText {
    margin-top: 5px;
}

@media (max-width: 1050px) {
    .InnerWrapper {
        display: inline-block;
    }
    .Clogo{
        height: 140px;
        width: 140px;
        margin-left: 70px;
    }
    .CompanyDetails {
        margin-left: 50px;
        padding: 0;
    }
  }