.Logo {
  padding: 2px 9px;
  align-items: center;
  line-height: 0px;
  justify-items: center;
  margin-top: 5px;
  align-content: center;
  color: #ffffff;
  background-color: white;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
}

.logo{
  margin: 10px auto;
  color: #34baf2;
  font-size: 30px;
  line-height: 20px;
  font-weight: bold;
}

@media (max-width: 800px) {
  .Logo h3 {
    font-size: 18px;
  }
  .subscript {
    display: none;
  }
}

.subscript {
  font-size: 15px;
  margin-left: 5px;
  border: 2px solid white;
  padding: 1px;
  border-radius: 3px;
}

.secondhalf {
  color: #EE382B;
}
