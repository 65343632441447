.ResultsTable .TableWrapper {
  margin: 0 10px;
  padding-bottom: 20px;
  overflow: auto;
}

.ResultsTable .Toolbar {
  margin: 25px auto;
  padding-right: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.ResultsTable .ConvertBtn button {
  padding: 10px 15px;
  background-color: #2e7d32;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  outline: none;
  border-radius: 5px;
}

.ResultsTable .NotDisplay {
  display: none;
}

.ResultsTable table {
  margin: 20px;
  font-size: 0.9em;
  border-collapse: collapse;
  align-items: center;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .ResultsTable table {
    width: 90%;
  }
}

@media screen and (min-width: 1000px) {
  .ResultsTable table {
    width: 90%;
  }
}

.ResultsTable table,
th,
td {
  border: 2px solid #ccc;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 16px;
}

.ResultsTable table thead {
  border-radius: 10px;
  background-color: #34baf2;
  color: #fff;
  font-size: 1.1em;
}

.ResultsTable table thead th {
  padding: 5px;
}

.ResultsTable table tbody {
  background-color: rgba(204, 204, 204, 0.1);
}

.ResultsTable table tbody tr:hover {
  cursor: pointer;
  background-color: rgb(211, 230, 243) !important;
  transition: 300ms;
}

.ResultsTable table td,
.ResultsTable table th {
  overflow: hidden;
  width: 300px;
  max-width: 300px;
}

.ResultsTable table th.Index,
.ResultsTable table td.Index {
  width: 60px !important;
}

.ResultsTable table th.SelectBox,
.ResultsTable table td.SelectBox {
  width: 30px !important;
  text-align: center;
}

.ResultsTable table th.SelectBox input,
.ResultsTable table td.SelectBox input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

@media (max-width: 650px) {
  .ResultsTable table td,
  .ResultsTable table th {
    max-width: 200px;
  }
  .ResultsTable .Toolbar {
    padding-right: 0;
  }
}
