.HomePageWelcome {
  text-align: center;
  align-content: center;
  
  max-width: 80%;
}

.HomePageWelcome h1 {
  color: #34baf2;
}

.MainContent {
  background-color: #34baf2;
  padding: 10px;
  display: flex;
  vertical-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainContent ol {
  color: white;
  font-size: 25px;
  float: left;
}

.MainContent h3 {
  color: white;
  padding-left: 10px;
  font-size: 30px;
  font-weight: bold;
}

.HomePageWelcome img {
  width: 100%;
  width: 50%;
  max-width: 800px;
  min-width: 350px;
  height: auto;
  height: 50%;
  margin-top: 100px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.HomePageWelcome a {
  text-decoration: none;
}
.HomePageWelcome button {
  padding: 10px 30px;
}

.HomePage {
  padding: 20px 20px;
  margin: 10px auto;
  width: 90%;
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .HomePage {
    width: 100%;
  }
}

.LoginButton {
  background-color: white;
  border: 2px solid #34baf2;
  border-radius: 5px;
  font-size: 20px;
  color: #34baf2;
  padding: 5px;
  width: 200px;
}

.RegisterButton {
  background-color: #EE382B;
  border: none;
  width: 200px;
  border-radius: 5px;
  margin-right: 50px;
  font-size: 20px;
  font-weight: 500;
  color: white;
  padding: 15px;
  transition: all 0.5s;
}

.RegisterButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.RegisterButton span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.RegisterButton:hover span {
  padding-right: 25px;
}

.RegisterButton:hover span:after {
  opacity: 1;
  right: 0;
}

.RegisterButton:hover {
  background-color: #EE382B;
}

@media (max-width: 800px) {
  .RegisterButton {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .MainContent {
    display: block;
    justify-content: center;
    align-items: center;
  }
}

.Prompt {
  margin-bottom: 50px;
  font-size: 20px;
}

.Prompt h3 {
  color: #485057;
  margin-top: 40px;
  margin-bottom: 30px;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.DataImg {
  width: 100%;
  width: 30%;
  max-width: 800px;
  min-width: 400px;
  height: auto;
  height: 30%;
  margin-top: 100px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.Footer span {
  color: #34baf2;
  justify-content: center;
  align-items: center;
  align-content: center;
  vertical-align: center;
}

.Footer {
  text-align: center;
}

.demo {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 15px;
}

.subscript {
  font-size: 15px;
  margin-left: 5px;
  border: 2px solid #34baf2;
  padding: 1px;
  border-radius: 3px;
}

.mean {
  color: #34baf2;
}

.TopGroup {
  display: flex;
  justify-content: space-between;
}

.sortGroup {
  display: flex;
}

.select {
  color: #485057;
}

.GetStarted {
  background-color: #0D9BD8;
  background-image: url("./ok.png");
  background-position: right 50px;
  background-size: 750px 400px;
  background-repeat: no-repeat;
  width: 98.95vw;
  padding: 60px;
  text-align: left;
}

.GetStartedd {
  background-color: #0D9BD8;
  width: 98.95vw;
  padding: 40px;
  text-align: center;
}

@media (max-width: 800px) {
  .GetStarted{
    background-blend-mode: none;
  }
}

.headtext {
  font-size: 2.5rem;
  color: white;
  font-weight: 700;
}

.subtext {
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 30px;
  color: white;
}

.features {
  padding: 30px;
  text-align: center;
  margin-top: 20px;
}

.featmaintxt {
  font-size: 2.2rem;
  color: #0D9BD8;
  font-weight: 600;
}

.card {
  padding: 0;
  margin: 50px;
  border: none;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
-moz-box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border-radius: 15px;
  -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 2s;
}

@media (max-width: 800px) {
  .card{
    margin: 10px;
  }
}

@media (max-width: 1200px) {
  .GetStarted{
    background-image: none;
  }
}

@-webkit-keyframes slide {
  100% { left: 0; }
}

@keyframes slide {
  100% { left: 0; }
}

.card:hover {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.cardcont {
  width: 100%;
  text-align: left;
  padding: 20px;
  border: 2px solid #DFDFDF;
}

.cardimg {
  background-color: #0D9BD8;
}

.cardbody {
  padding: 0;
}

.cardtitle {
  font-size: 1.5rem;
  font-weight: 500;
}