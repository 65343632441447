.TopMenuItems {
  margin-bottom: 20px auto;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}
.Back {
  background-color: #34baf2;
  padding: 6px;
  color: white;
  border: none;
  margin-left: 15px;
}

@media (min-width: 800px) {
  .TopMenuItems {
    flex-flow: row;
  }
}
