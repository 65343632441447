html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.ReportTable table {
  width: 100%;
  margin: 20px;
  font-size: 0.85em;
  width: 60vw;
  border-collapse: collapse;
  table-layout: fixed;
}

.ReportTable table thead {
  background-color: #34baf2;
  color: #fff;
  font-size: 1.3em;
  padding: 5px;
}

.tableHeading th {
  text-align: center !important;
}

.ReportTable table tbody {
  background-color: #fff;
}

.ReportTable table td,
.ResultsTable table th {
  overflow: hidden;
  padding: 10px 10px;
  width: 300px;
  max-width: 300px;
}

@media (max-width: 780px) {
  .ReportTable table {
    width: 80vw;
  }
}

@media (max-width: 550px) {
  .ReportTable table td,
  .ResultsTable table th {
    max-width: 200px;
  }
}

.RChartContainer .recharts-legend-wrapper {
  position: relative !important;
}

.pieContainer {
  /* padding: 100px; */
  background-color: white;
  margin-top: 15px;
  width: auto;
  height: fit-content;
}
