.table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  padding: "7px";
}

.row:nth-child(even){background-color: #f5f5f5;}

.row:hover {background-color: #ddd; cursor: pointer;}


.head {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #34baf2;
    color: white;
    border-top-left-radius: 20px;
  }

  .td {
    border: 1px solid #ddd;
  }

  .th {
      color: white;
  }
