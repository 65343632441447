.OverviewPage {
  justify-content: space-around;
  padding: 40px;
  background-color: #F6F6F6;
  margin: 0 auto;
  box-sizing: border-box;
}

@media (max-width: 500px) {
  .OverviewPage {
    margin-top: 35px;
    flex-direction: column;
    justify-content: center;
    max-width: 98vw;
  }
}
