.TopMenuItem {
  margin: 5px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.TopMenuItem a {
  color: #34baf2;
  text-decoration: none;
  width: 100%;
  font-size: 20px;
  box-sizing: border-box;
  display: block;
}

.TopMenuItem a:hover,
.TopMenuItem a:active,
.TopMenuItem a.active {
  color: #34baf2;
  text-decoration: none;
}

@media (min-width: 800px) {
  .TopMenuItem {
    margin: 0;
    display: flex;
    height: 100%;
    font-size: 1em;
    font-weight: 550;
    width: auto;
    align-items: center;
  }

  .TopMenuItem a {
    color: #34baf2;
    height: 100%;
    width: auto;
    padding: 8px 25px;
    border-bottom: 4px solid transparent;
  }

  .TopMenuItem a:hover,
  .TopMenuItem a:active,
  .TopMenuItem a.active {
    background-color: #f6f7f9;
    border-bottom: 2px solid #ee3a2c;
    color: #34baf2;
  }
}
