.companies {
    margin-top: 50px;
    float: left;
    margin-right: 45px;
    margin-left: 55px;
}

.errorun {
    width: 600px;
    height: 600px;
    opacity: 0.8;
}

.none{
    margin: auto;
  width: 50%;
}

.errormess {
    font-size: 30px;
}