.CompQuestionListContainer {
  padding-top: 90px;
  width: 40%;
}
.QuestionListContainer {
  /* padding-top: 85px; */
  flex: 1;
  max-width: 100%;
}

.QuestionList {
  width: 100%;
  list-style: none;
  padding: 0 10px;
  margin: 5px auto;
  box-sizing: border-box;
}
.GroupedQuestionList {
  margin: 0px auto 0px auto;
}

.QuestionList li {
  display: block;
  margin: 25px auto;
  border: 0.5px solid #cccccc;
  border-radius: 1px;
  background-color: #fdfdfd;
  box-sizing: border-box;
  transition: 300ms;
}
.QuestionList .CompLI {
  margin-top: 0px;
  background-color: #e7f7fd;
  border: 1px solid #34baf2;
}

.QuestionList li:hover {
  background-color: #e7f7fd;
  border: 1px solid #34baf2;
}

@media (max-width: 650px) {
  .QuestionList li {
    background-color: rgba(204, 204, 204, 0.2);
  }
}
.QuestionList .GroupLastQuestion {
  margin-bottom: 0px;
}
