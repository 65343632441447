.Title {
  margin-left: 20px;
  margin-top: 0.75em;
  /* margin-bottom: 0.75em; */

  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  font-size: 1.75rem;

  min-height: 34px;
}

.TableWrapper {
  width: 90vw;
  padding: 8px;
  margin: 30px auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgba(204, 204, 204, 0.3);
  box-sizing: border-box;
}

.innertable {
  margin-bottom: 40px;
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.fulltable {
  width: 100%;

  font-size: 0.85em;
  width: 60vw;
  border-collapse: collapse;
  table-layout: fixed;
}

.table {
  width: 70vw;
}
.tableheader {
  background-color: #34baf2;
  color: #fff;
  font-size: 1.3em;
  padding: 5px;
}

.serialNo {
  width: 30%;
}

.tableHeading th {
  text-align: center !important;
}

.tbodyy {
  background-color: #fff;
}

.Index {
  overflow: hidden;
  padding: 10px 10px;
  width: 300px;
  max-width: 300px;
}

@media (max-width: 780px) {
  .fulltable {
    width: 80vw;
  }
}

@media (max-width: 550px) {
  .Index {
    max-width: 200px;
  }
}
