.QuestionPreviewWrapper {
  margin: 15px;
  padding: 10px 0;
  cursor: pointer;
  box-sizing: border-box;
}

.GroupButton {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  border: none;
  box-sizing: border-box;
}

.GroupButton button {
  font-size: 0.9em;
  padding: 5px 4px;

  border: none;
  color: #fff;
  box-sizing: border-box;
  border: none;
}

@media (min-width: 799px) {
  .GroupButton button {
    margin: 0px;
  }
}

.Upward {
  background-color: #34baf2;
  border: none;
}

.Downward {
  background-color: #34baf2;
  border: none;
}

.Add {
  background-color: #34baf2;
  border: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.Remove {
  background-color: #ee3a2c;
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Rating {
  display: none;
}
