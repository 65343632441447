.SurveyItem {
  margin: 30px auto;
  background-color: white;
  padding: 25px;
  border: 2px solid #e8e8e8;
  border-radius: 9px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  box-sizing: border-box;
  transition: 300ms;
  -webkit-box-shadow: 0px 0px 25px -13px rgba(189, 189, 189, 1);
  -moz-box-shadow: 0px 0px 25px -13px rgba(189, 189, 189, 1);
  box-shadow: 0px 0px 25px -13px rgba(189, 189, 189, 1);
  width: 100%;
}

.SurveyItem:hover .editgrp {
  display: flex;
}

.SurveyItem h3,
.SurveyItem p {
  margin: 10px;
  display: inline-block;
}

.SurveyItem h3 {
  color: #333333;
  font-weight: 400;
}

.SurveyItem button {
  margin: 2px;
  max-height: 35px;
}

.Switcher {
  display: block;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.GroupeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

@media (max-width: 700px) {
  .GroupeButton {
    flex-direction: column;
  }
  .GroupeButton button {
    width: 100%;
  }
  .Switcher label {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
  }
}

.ViewButton {
  padding: 5px;
  color: white;
  background-color: #34baf2;
  border: none;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  font-weight: bold;
  padding-left: 10px;
}

.PushButton {
  padding: 5px;
  color: white;
  background-color: #28a744;
  border: none;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  font-weight: bold;
  padding-left: 10px;
}

.View {
  margin-right: 10px;
}

.DeleteButton {
  padding: 5px;
  font-weight: bold;
  color: white;
  background-color: #ee3a2c;
  border: none;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.Delete {
  margin-right: 10px;
}

.ViewButtonn {
  background-color: white;
  padding: 7px;
  border: 1px solid #333333;
}

.DeleteButtonn {
  background-color: white;
  padding: 7px;
}

.editgrp {
  margin-top: 15px;
  display: none;
  border: 1px solid #333333;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  background-color: white;
  border-radius: 10px;
  padding-bottom: 10px;
}

.modal_title {
  background-color: #34baf2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  padding: 15px;
  font-size: 20px;
  font-weight: bold;
}

.modal_description {
  display: block;
  position: relative;
  padding: 15px;
  height: max-content;
  overflow-y: scroll;
}

.checkbox_list label {
  padding-left: 10px;
}
