.Text {
  resize: none;
  overflow: hidden;
  width: 100%;
  border: none;
  background: transparent;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  font-size: 1.75rem;
}
