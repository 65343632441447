.OverviewEditer {
  border: 2px solid #E8E8E8;
  padding: 15px 30px;
  border-radius: 3px;
  background-color:white;
  box-sizing: border-box;
  display: block;
  -webkit-box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
-moz-box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
}

.OverviewEditer p,
.OverviewEditer h2 {
  margin: 0.75em 0;
}

.OverviewEditer h2 {
  color: #34baf2;
}

.OverviewEditer a {
  margin-bottom: 5px;
  text-decoration: none;
  color: #e91e63;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.OverviewEditer a:hover {
  color: #1a237e;
}

@media (max-width: 500px) {
  .OverviewEditer {
    width: 100%;
    margin: 5px 0;
    font-size: 0.9em;
  }
}

.OverviewEditer button {
  color: white;
  background: #34baf2;
  font-size: 15px;
  border: none;
  padding: 5px;
  margin-left: 10px;
}

.datepick {
  padding: 4px;
  border: 2px solid #34baf2;
  border-radius: 5px;
}
