.NoData {
  margin-left: 45%;
  color: red !important;
  margin-top: 10%;
}
.AddButton {
  color: white;
  padding: 10px;
  font-size: 20px;
  display: flex;
  width: fit-content;
  margin-right: 30px;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  background-color: rgb(238, 58, 44);
  border: none;
}
.SaveButton {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 17px;
  display: flex;
  width: fit-content;
  margin-right: 30px;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  background-color: #34baf2;
  border: none;
  border-radius: 0;
  margin-left: 20px !important;
}

.CancelButton {
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 17px;
  display: flex;
  width: fit-content;
  margin-right: 30px;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  background-color: #34baf2;
  border: none;
  border-radius: 0;
}

.AddIcon {
  margin-right: 10px;
}

.GroupName {
  border: 2px solid #34baf2;
  border-radius: 3px;
  min-height: 30px;
  margin-top: 2px;
  margin-bottom: 35px;
  font-size: 18px;
  width: 40vw;
  padding: 15px;
  outline: none;
}

.ActionButton {
  text-decoration: underline;
  background-color: white;
  border: 0;
  color: #34baf2;
}
