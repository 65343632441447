.tobeprinted {
    display: none;
}

.logo {
    width: 300px;
    height: 300px;
    border-radius: 15px;
    margin-bottom: 40px;

}

.companyname {
    font-size: 60px;
    color: #585858;
    text-overflow: ellipsis;
}

@media print {
    .dontprint {
        display: none;
     }
     .tobeprinted {
        display: block;
        size: landscape;
     }

     .printbtn {
         display: none;
     }

 }

 .firstpage {
     padding: 40px;
     align-items: center;
     margin-top: 150px;
     page-break-after: always;
 }

 .header {
    text-align: center;
    align-items: center;
 }

 .details {
     margin-top: 100px;
     font-size: 50px;
 }

 .survtit {
     margin-top: 30px;
     font-size: 35px;
    color: #34baf2;
    text-overflow: ellipsis;
 }

 .survtitt {
    margin-top: 20px;
    font-size: 30px;
   color: black;
   text-overflow: ellipsis;
}

.powered {
    margin-top: 370px;
    color: #34baf2;
    font-size: 35px;
}

.openhead {
    background-color: #34baf2;
    color: white;
    padding: 20px;
    margin-bottom: 70px;
}



.reportgenitem {
  margin-top: 60px;
  margin-right: 50px;
  margin-left: 50px;
  display: flex;
  margin-bottom: 40px;
  background-color: white;
  padding: 20px;
  border: 2px solid #E8E8E8;
  border-radius: 9px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  box-sizing: border-box;
  transition: 300ms;
  -webkit-box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
-moz-box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
}

@media (max-width: 700px) {
    .reportgenitem {
        display: block;
    }
  }