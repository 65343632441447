.Login {
  margin: 20px auto;
  width: 500px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #e7f7fd;
  border: 2px solid #34baf2;
  padding: 30px;
}

.Login h3 {
  margin-bottom: 10px;
  color: #34baf2;
  font-size: 1.5em;
  margin: 20px auto;
  letter-spacing: 1px;
}

.Login a {
  text-decoration: none;
}

@media (max-width: 499px) {
  .Login {
    width: 90%;
  }
}

.Error {
  color: white;
  background-color: #ee3a2c;
  border-radius: 5px;
  padding-top: 10px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  vertical-align: center;
  font-weight: bold;
  padding-bottom: 20px;
}

.SubmitButton {
  background-color: #34baf2;
  border: 2px solid #34baf2;
  color: white;
  font-size: 20px;
  padding: 7px;
  width: 100%;
  margin-top: 30px;
  margin-right: 40px;
}
.SubmitButton:focus,
.SubmitButton:hover {
  outline: none;
  background-color: #1cb2f0;
}

.Register {
  background-color: white;
  border: 2px solid #34baf2 !important;
  color: #34baf2;
  font-size: 20px;
  padding: 7px;
  width: 100%;
  margin-top: 20px;
  margin-right: 40px;
}
.Register:hover {
  background: #34baf2;
  color: #fff;
}
.ForgotLink {
  color: #34baf2;
  padding-top: 10px;
  text-align: right;
}

.textLeft {
  text-align: left;
}

.fullWidth {
  width: 100%;
}

.pageWrapper {
  display: flex;
  height: 100vh;
  padding: 0px;
}
.contentWrapper {
  background-color: #ffffff;
  height: 100vh;
  display: flex;
  flex: 1.4 1;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
}

.contentWrapper h3 {
  margin-bottom: 10px;
  color: #34baf2;
  font-size: 1.5em;
  margin: 10px auto;
  letter-spacing: 1px;
}

.imageContainer {
  display: block;
  margin: auto;
}
.imageContainer > img {
  margin: auto;
  height: 250px;
}
@media only screen and (min-width: 1025px) {
  .imageWrapper {
    display: flex;
    background-size: 100% 100%;
    background: url("frame_generic_dark.png") no-repeat;
    background-position: left 90px top 130px;

  background-size: 860px 600px;
  background-repeat: no-repeat;
    background-color: #0e9bd8;
    flex: 3;
  }
  .registerWrapper {
    display: block;
    overflow: hidden;
    background-size: 100% 100%;
    width: 100%;
    background-color: #0e9bd8;
    flex: 3;
  }
  .resetWrapper {
    display: flex;
    background-size: 100% 100%;
    background: url("wfh_8.svg") no-repeat;
    background-color: #0e9bd8;
    flex: 3;
  }
}

@media (max-width: 1025px) {
  .imageWrapper {
    display: none;
  }
  .registerWrapper {
    display: none;
  }
}

.Welcome {
  margin-bottom: 25px;
  color: #34baf2;
}

.Terms {
  color: #737373;
  margin-top: 15px;
}

.terms_link {
  color: #34baf2;
  font-weight: bold;
}

.sidetext {
  margin: 50px 20px;
  text-align: center;
  font-weight: bold;
  justify-content: center;
  width: 100%;
  display: flex;
}

.headerside {
  color: white;
  font-weight: bold;
  font-size: 35px;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.features {
    margin: 90px;
    background-color: white;
    padding: 10px;
    height: fit-content;
    width: fit-content;
    margin: auto;
    text-align: center;
    border: 2px solid #E8E8E8;
    border-radius: 9px;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    transition: 300ms;
    -webkit-box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
  -moz-box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
  box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
}

.featureitem {
  display: flex;
  justify-items: center;
  color: #495058;
  vertical-align: middle;
  margin: 35px 15px;
  
}

.features {  
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }    
}

.featuredesc {
  margin-left: 30px;
  font-size: 25px;
}

.featurebody {
  margin-top: 30px;
}

.featuretitle  {
  color: white;
}