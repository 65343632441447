.SurveyHeader {
  cursor: pointer;
  transition: 300ms;
  border: 0.5px solid #cccccc;
  border-radius: 1px;
  padding: 20px;
  margin: 0 5px;
  background-color: #fdfdfd;
  margin-top: 5px;
  box-sizing: border-box;
  color: #333333;
}

.SurveyHeader:hover {
  background-color: #e7f7fd;
  border: 1px solid #34baf2;
}

.ForMobile {
  display: none;
}

@media (max-width: 650px) {
  .SurveyHeader h1 {
    font-size: 1.6em;
    margin: 0.5em 0;
  }
  .ForMobile {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 3px;
    box-sizing: border-box;
  }
  .ForMobile button {
    font-size: 0.9em;
    padding: 5px 4px;
    color: #fff;
    box-sizing: border-box;
  }
}

.CompSurvey {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
.CompSurvey .GroupButton {
  color: black;
  border: 1px solid #34baf2;
}
.CompSurvey .GroupButton:hover {
  border: 1px solid #0069d9;
  color: #34baf2;
}

.ReportLayoutOptionText {
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}
.CountField {
  outline: none;
  border: 2px solid #ccc;
  background-color: white;
  border-radius: 5px;
  font: inherit;
  padding: 6px 10px;
  width: 50px;
  box-sizing: border-box;
  transition: 300ms;
  margin-right: 5px;
}
.EmailGroupContainer {
  display: flex;
  flex-direction: column;
}
.EmailGroup {
  display: flex;
  margin-top: 7px;
  justify-content: flex-start;
  align-items: center;
}
.CountEnabled {
  display: flex;
  align-items: center;
}
.CountField::-webkit-outer-spin-button,
.CountField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.SurveyGrouping {
  cursor: pointer;
}
.TransferListHeadings {
  text-align: center;
  margin-bottom: 0;
}
