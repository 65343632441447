.EmailId{
    font-size: 1.2em;
    font-weight: bold;
    text-decoration: underline;
}

.audititem {
    margin: 20px;
    padding: 20px;
    box-shadow: 0px 0px 5px #888888;
    border-radius: 5px;
    word-wrap: break-word;
    background-color: #E8E8E8;
    -webkit-box-shadow: 0px 0px 25px -13px rgba(189, 189, 189, 1);
  -moz-box-shadow: 0px 0px 25px -13px rgba(189, 189, 189, 1);
  box-shadow: 0px 0px 25px -13px rgba(189, 189, 189, 1);
}