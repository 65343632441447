.Switcher {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-top: 15px;
  box-sizing: border-box;
}

.Container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1em;
  width: 1em;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 9px;
  background-color: #fff;
  transition: 300ms;
}

.check {
  padding: 10px;
  color: aqua;
}

.ScrollBar {
  height: 17em;
  overflow-y: scroll;
}

.Radio {
  align-content: flex-start;
  float: left;
}
