.Header {
    background-color: #F6F7F9;
    padding: 25px;
    display: flex;
}

.Back {
    background-color: #34baf2;
    padding: 6px;
    color: white;
    border: none;
    margin-left: 15px;
}

.vertical {
    border-left: 1px solid #d1d6e0;
    margin-left: 20px;
    height: inherit;
}

.HeaderText {
    margin-top: 5px;
}

.Search {
    display: flex;
    background-color: #34baf2;
    color: white;
    padding: 25px;
}

.searchbox {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: none;
    font-size: 20px;
}

.UserInfo {
    margin: 30px;
    padding: 25px;
}

.Attribute {
    color: #484848;
    font-size: 20px;
    font-weight: 800px;
    margin-right: 10px;
    margin-bottom: 15px;
}

.Value {
    color: #484848;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 15px;
}

.searchbtn {
    padding: 10px;
    background-color: red;
    color: white;
    margin-left: 20px;
    border: none;
    font-size: 20px;
    border-radius: 10px;
}

.addhbtn {
    padding: 10px;
    background-color: red;
    color: white;
    margin-top: 20px;
    border: none;
    font-size: 20px;
    border-radius: 10px;
}