.NewSurvey {
    background-color: #34baf2;
    color: white;
    border: none;
    font-weight: bold;
    padding: 10px;
    font-size: 20px;
    border: 2px solid #34baf2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .NewSurvey:hover {
    background-color: white;
    color: #34baf2;
    border: 2px solid #34baf2;
  }
  
  .Icon {
    margin-right: 10px;
  }
  
  .CreateSurvey {
    display: flex;
  }
  
  .TemplateSurvey {
    background-color: #ee3a2c;
    color: white;
    border: none;
    font-weight: bold;
    padding: 10px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  
  .formControl {
    width: fit-content;
  }
  
  .DropDown {
    background-color: #34baf2;
  }
  
  .CancelButton {
    background-color: #ee3a2c;
    color: white;
    font-size: 15px;
    border: none;
    padding: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .Blank {
    font-size: 15px;
    color: white;
    background-color: #34baf2;
    padding: 10px;
    border: none;
    margin: 5px;
  }
  
  .Board {
    font-size: 15px;
    color: white;
    background-color: #34baf2;
    padding: 10px;
    border: none;
    margin: 5px;
  }
  
  .Chair {
    font-size: 15px;
    color: white;
    background-color: #34baf2;
    padding: 10px;
    border: none;
    margin: 5px;
  }
  
  .Member {
    font-size: 15px;
    color: white;
    background-color: #34baf2;
    padding: 10px;
    border: none;
    margin: 5px;
  }
  
  .DialogTitle {
    color: #34baf2;
    font-size: 1.3em;
  }
  
  .CName {
      font-size: 100px;
  }

  .group {
      display: block;
  }

  .LogoUpload {
      background-color: #34baf2;
      color: white;
      border-radius: 5px;
      border: none;
      padding: 10px;
      display: block;
      margin-top: 15px;
  }

  .CreateButton {
        background-color: #34baf2;
        color: white;
        font-size: 15px;
        border: none;
        padding: 15px;
        margin-right: 5px;
        margin-bottom: 10px;
  }

  .uploadlabel {
      color: #000;
      font-weight: bold;
      margin-right: 10px;
  }

  .Info {
      margin-bottom: 25px;
  }
  
  .ButtonGrp {
    display: flex;
    justify-content: space-between;
  }

  .sortgroup {
    display: flex;
  }