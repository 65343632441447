.TestPage {
  text-align: center;
  background-color: red;
}

.Title {
  width: 70%;
  display: block;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  margin: 50px auto;
  box-sizing: border-box;
}
