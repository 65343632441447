.TopMenu {
    height: 40px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0px 50px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 90;
}

@media print {
    .TopMenu {
        display: none;
    }
}

.TopMenu nav{
    height: 100%;
}

@media(max-width: 800px) {
    .TopMenu{
        display: none;
    }
}
