.Toolbar {
  height: 70px;
  width: 100%;
  background-color: #34baf2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 90;
}

@media print {
  .Toolbar {
    display: none;
  }
}

.Toolbar nav {
  height: 100%;
}

.Logo {
  height: 80%;
}

@media (max-width: 800px) {
  .DesktopOnly {
    display: none;
  }
  .Toolbar {
    padding: 0px 20px;
  }
}

.companyDetail {
  margin-top: 12px;
}

.NavContainer {
  display: flex;
  align-items: center;
}
