.table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  padding: 7px;
  
}

.row:nth-child(even){background-color: #f5f5f5;}

.row:hover {background-color: #ddd; cursor: pointer;}


.head {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #34baf2;
    color: white;
    border-top-left-radius: 20px;
  }

  .td {
    border: 1px solid #ddd;
  }

  .th {
      color: white;
  }

  .item {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .SendButton {
    padding: 10px;
    color: white;
    background-color: #34baf2;
    font-size: 20px;
    border: none;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .FileItem {
    cursor: pointer;
    font-style: italic;
    text-decoration: underline
  }

  .MailHeading{
    font-weight: bold;
    font-size: 16px;
  }

  .MailBody{
    margin-left: 15px;
  }