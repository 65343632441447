.Header {
    background-color: #F6F7F9;
    padding: 25px;
    display: flex;
}

.HeaderText {
    margin-top: 5px;
}

.vertical {
    border-left: 1px solid #d1d6e0;
    margin-left: 20px;
    height: inherit;
}

.Back {
    background-color: #34baf2;
    padding: 6px;
    color: white;
    border: none;
    margin-left: 15px;
}