.SurveyEditerPage {
  width: 100%;
  min-height: 600px;
  margin: 50px auto;
  background-color: #f6f6f6;
  display: flex;
  box-sizing: border-box;
}

.SurveyEditerPage .SurveyPreview {
  width: 80%;
  flex: 1;
  min-height: 1000px;
  max-height: none;
  margin: 40px;
  margin-right: 0px;
  padding: 20px 30px;
  border-radius: 5px;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 40px 6px rgb(204 204 204 / 45%);
  -moz-box-shadow: 0 0 40px 6px rgb(204 204 204 / 45%);
  box-shadow: 0 0 40px 6px rgb(204 204 204 / 45%);
  background-color: white;
}

.SurveyEditerPage .SideBar {
  width: 30%;
  min-height: 500px;
  position: initial;
  margin: 3px 3px;
  box-sizing: border-box;
  background-color: white;
}

.SurveyEditerPage .Footer {
  text-align: center;
  width: 100vw;
  position: fixed;
  bottom: 0;
  background-color: rgb(204 204 204 / 55%);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* right: 1; */
}

.SurveyEditerPage .ForMobile {
  display: none;
}

.SurveyEditerPage .Footer button {
  padding: 10px;
  background-color: #34baf2;
}

button {
  border-radius: 5px;
  cursor: pointer;
  transition: 300ms;
}

button:hover {
  color: white;
  background-color: #34baf2;
}

button:active {
  color: white;
  background-color: #ee3a2c;
}

.SmallScreen {
  display: none;
}

@media (max-width: 1000px) {
  .SurveyEditerPage {
    width: 98%;
  }
}

@media (max-width: 800px) {
  .SurveyEditerPage {
    width: 100%;
  }
  .SurveyEditerPage .SurveyPreview {
    width: 60%;
  }
  .SurveyEditerPage .SideBar {
    width: 40%;
  }
}

@media (max-width: 650px) {
  .SurveyEditerPage .SideBar {
    display: none;
  }

  .SurveyEditerPage .SurveyPreview {
    width: 98%;
    padding: 0;
    margin-bottom: 60px;
  }

  .SurveyEditerPage .ForMobile {
    display: block;
    height: 70px;
    position: fixed;
    bottom: 0;
    border: 1px solid #ccc;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    box-sizing: border-box;
  }

  .SurveyEditerPage .ForMobile .Container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.6em;
    padding: 0 5px;
    box-sizing: border-box;
  }

  .SurveyEditerPage .ForMobile button {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
