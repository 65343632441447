.Container {
  padding: 2px 5px;
  margin:  0;
  box-sizing: border-box;
}

@media (max-width: 500px) {
  .Container {
    width: 100%;
    margin: 50px 0;
    margin-top: 5px;
    padding-bottom: 20px;
  }
  .OverviewData {
    font-size: 0.9em;
  }
  .Container button {
    width: 100%;
    margin: 40px;
  }
}

.DeleteButton {
  color: white;
  padding: 10px;
  font-size: 20px;
  display: flex;
  margin-top: 30px;
  width: fit-content;
  margin: 30px auto;
  justify-content: center;
  align-items: center;
  background-color: rgb(238, 58, 44);
  border: none;
}

.Delete {
  margin-right: 10px;
}

.item {
  border: 2px solid #E8E8E8;
  border-radius: 3px;
  padding: 7px 15px;
  margin-bottom: 10px;
  background-color: white;
  display: table-cell;
    text-align: center;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
-moz-box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
box-shadow: 0px 0px 25px -13px rgba(189,189,189,1);
}

.divitm {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.ResponderDetail{
  color: #34baf2;
  font-size: medium;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}