.ShareComponent {
  padding-left: 30px;
  margin-top: 30px;
}

.ShareForm h2 {
  color: #34baf2;
  margin-bottom: 30px;
}

.Recipient {
  border: 2px solid #34baf2;
  border-radius: 3px;
  min-width: 200px;
  min-height: 30px;
  margin-top: 2px;
  margin-bottom: 15px;
  font-size: 20px;
  width: 80vw;
  padding: 15px;
}

.Subject {
  border: 2px solid #34baf2;
  border-radius: 3px;
  min-width: 200px;
  min-height: 30px;
  margin-top: 2px;
  margin-bottom: 15px;
  font-size: 20px;
  width: 80vw;
  padding: 15px;
}
.SelectEmailGroup {
  border: 2px solid #34baf2;
  border-radius: 3px;
  min-height: 30px;
  margin-top: 2px;
  margin-bottom: 35px;
  font-size: 18px;
  width: 12vw;
  margin-right: 10px;
  padding: 15px;
}

.Message {
  border: 2px solid #34baf2;
  border-radius: 3px;
  min-width: 200px;
  min-height: 30px;
  margin-top: 2px;
  margin-bottom: 15px;
  font-size: 20px;
  width: 80vw;
  padding: 15px;
}

.SendButton {
  padding: 10px;
  color: white;
  background-color: #34baf2;
  font-size: 20px;
  border: none;
  margin-top: 5px;
  margin-bottom: 20px;
}

.Link {
  float: left;
  padding-left: 10px;
  display: block;
  font-size: 15px;
  font-size-adjust: inherit;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.LinkShare {
  background-color: aliceblue;
  display: flex;
  align-items: center;
  width: 80vw;
  margin: 30px;
  border-radius: 10px;
}

.LinkButton {
  background: #34baf2;
  font-size: 15px;
  margin-left: 15px;
  color: white;
  height: 70px;
  font-weight: bold;
  border: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.LinkShare h2 {
  color: #34baf2;
}

.LinkText {
  padding-left: 30px;
  padding-top: 30px;
}

.Span {
  margin-left: 10px;
  font-weight: bold;
}

.FileUpload {
  border: 2px solid #34baf2;
  border-radius: 3px;
  min-width: 100px;
  min-height: 30px;
  margin-top: 2px;
  margin-bottom: 15px;
  font-size: 20px;
  width: 80vw;
  padding: 15px;
}

.FileItem {
  margin-left: 10px;
  font-size: 1.2em;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.DeleteFile {
  color: #e91e63;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
}

.HistoryButton {
  padding: 10px;
  color: white;
  background-color: #34baf2;
  font-size: 20px;
  border: none;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.TopMenu {
  display: inline;
  cursor: pointer;
  color: #34baf2;
  font-size: 1.5em;
  font-weight: 500;
}

.History {
  margin-left: 30px;
}

.UNDERLINETEXT {
  text-decoration: underline;
}
