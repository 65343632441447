.compMemb {
    display: block;
    float: right;
    margin-top: 5px;
}

.compname {
    margin: 0px 0px 0px 15px;
    color: white;
}

.toolbarLogo {
    border-radius: 10px;
    width: 64px;
    height: 64px;
  }

.companyBox {
    margin-bottom: 12px;
}