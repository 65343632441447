.ReactTable .rt-tbody .rt-tr-group {
  /* margin-bottom: 25px; */
  min-height: 165px;
}

.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
  display: flex;
  align-items: center;
  /* min-width: 190px; */
}
/* .ReactTable .rt-table .rt-thead {
  min-width: 750px !important;
} */
/* .ReactTable .rt-table .rt-tbody {
  min-width: 750px !important;
} */
.ReactTable * {
  white-space: pre-wrap;
}
.ReactTable .rt-thead .rt-th {
  padding-bottom: 10px;
}
/* .ReactTable .rt-table .rt-thead {
  overflow: hidden;
}
.stickyheader {
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
} */
