.ErrorContainer {
  text-align: center;
  align-content: center;
}

.ErrorImg {
  width: 100%;
  width: 40%;
  max-width: 800px;
  min-width: 400px;
  height: auto;
  height: 40%;
  margin: 0;
}

.Error {
  color: #34baf2;
  font-size: 40px;
  margin: 0;
}

.Return {
  padding: 15px;
  color: white;
  font-size: 20px;
  border: none;
  background-color: #34baf2;
  font-weight: bold;
}
