.CompanyItem {
    margin-top: 20px;
    border-radius: 135px;
    align-items: center;
    justify-content: center;
    
}

.item {
    display: flex;
}

.CompanyHeader {
    padding: 20px;
    display: flex;
    width: 35vw;
}

.name {
    color: white;
    font-size: 30px;
    margin-left: 10px;
    vertical-align: middle;
}

.OpenCompanyBtn{
    width: 100%;
    border: none;
    margin: auto;
}

.ViewButton {
    width: 100%;
    border: none;
    padding: 15px;
    font-weight: bold;
    background-color: #34baf2;
    color: white;
    border-radius: 0;
}

.ViewButton:hover {
    background-color: #0e9bd8;
}

.logo {
    border-radius: 10px;
    width: 75px;
    height: 75px;
    margin-top: 20px;
}

.name {
    padding-left: 0;
    color: black;
    width: 300px;
    overflow: hidden;
    max-width: 50ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    height: 45px;
}

.cin {
    display: flex;
    padding-left: 15px;
}

.statusMemb {
    display: block;
    padding: 15px;
}

.cinno {
    padding: 4px;
    margin-left: 10px;
    border-radius: 8px;
}

.cintitle {
    margin-top: 5px;
}

.activetill {
        display: flex;
        padding-left: 15px;
}