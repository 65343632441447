.EditTab {
  display: initial;
  width: 100%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  box-sizing: border-box;
  border: none;
}
.EditTab .SwitchTab {
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.tabbutton {
  background-color: #34baf2;
  border-radius: 0px;
  color: white;
  border: none;
  padding: 10px;
  font-weight: bold;
}

.tabbutton.active {
  background: #218838;
}

.EditTab .SwitchTab button {
  min-height: 40px;
  width: 33.32%;
  align-content: center;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.EditTab .SwitchTab button:focus {
  outline: none;
  border-right: none;
}

.EditTab .Content {
  width: 100%;
  min-height: 40vh;
  padding: 5px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.EditTab .Content input,
.EditTab .Content label,
.EditTab .Content textarea {
  display: block;
  padding: 5px;
  margin: 3px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.EditTab .Content input {
  outline: none;
  border: 2px solid #ccc;
  background-color: white;
  border-radius: 5px;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 95%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
}

.EditTab .Content input:hover {
  outline: none;
  border: 2px solid rgba(22, 22, 22, 0.8);
}

.EditTab .Content input:focus {
  outline: none;
  border: 2px solid #34baf2;
  background-color: rgb(232, 240, 254);
}

.EditTab .Content textarea {
  outline: none;
  border: 2px solid #ccc;
  background-color: white;
  border-radius: 5px;
  font: inherit;
  min-height: 6em;
  padding: 6px 10px;
  display: block;
  width: 95%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
}

.EditTab .Content textarea:hover {
  outline: none;
  border: 2px solid rgba(22, 22, 22, 0.8);
}

.EditTab .Content textarea:focus {
  outline: none;
  border: 2px solid #34baf2;
  background-color: rgb(232, 240, 254);
}

@media (max-width: 650px) {
  .EditTab .SwitchTab {
    display: none;
  }
}
